<template src="./_shared.html">
</template>

<script>
import consultar from '@/services/consultar.js';
//cores
export default {

    components: {
        ...consultar.components,
    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/parametros/cores',
            clone: '',
            obj: {},
            actionButtons:[
                {
                    text: 'Editar',
                    className: 'editar padrao',
                    icon: 'fas fa-edit',
                    color: 'default',
                    visible: true,// inicial
                    visibleOnRowSelected: true,
                    title: 'Editar item selecionado',
                    callback: that.handleEditarButton
                },
                {
                    text: '',
                    className: 'novo padrao',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: true,
                    title: 'Incluir nova carroceria',
                    callback: that.handleNovoButton
                },
                {
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.exportar
                },
            ],
            filtros:
            {
                data: [
                    {
                        id: 'descricao',
                        title: 'Descrição',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                    {
                        id: 'Ativo',
                        title: 'Status',
                        active: false,
                        value: 'true',
                        source: [
                            {
                                id: 'Ativo',
                                title: 'Ativo',
                                value: 'true'
                            },
                            {
                                id: 'Ativo',
                                title: 'Inativo',
                                value: 'false'
                            }
                        ],
                        sourceType: 'radio'
                    }
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todas cores',
                        filtro: []
                    },
                    {
                        id: 1,
                        title: 'Cores Ativas',
                        filtro:
                            [
                                {
                                    id: 'Ativo',
                                    value: true,
                                },
                            ]
                    }
                ],
                visaoSelecionada: 0,
                visible: true
            }
        };
    },

    methods: {
        ...consultar.methods,
    },
    mounted: consultar.mounted,

    beforeMount: function () {

    }
};
</script>
